<template>
  <div>
    <slot :id="id" name="label">
      <label v-if="label" :for="id" class="form-label">
        {{ label }}
      </label>
    </slot>

    <Datepicker
      :model-value="modelValue"
      :readonly="readonly"
      :disabled="disabled"
      :format="format"
      month-name-format="long"
      position="left"
      auto-apply
      hide-offset-dates
      :min-date="minDate"
      :max-date="maxDate"
      :min-time="minTime"
      :max-time="maxTime"
      :start-time="startTime"
      :is24="is24"
      :inline="inline"
      :enable-time-picker="!disableTimePicker"
      :start-date="startDate"
      :disabled-week-days="disabledWeekDays"
      :disable-month-year-select="disableMonthYearSelect"
      :placeholder="placeholder"
      :markers="markers"
      @update:modelValue="onUpdate"
    >
      <template #calendar-header="{ day }">
        <div class="text-xs font-light text-theme-7">
          {{ day }}
        </div>
      </template>

      <template #clock-icon>
        <i class="fal fa-clock" />
      </template>

      <template #calendar-icon>
        <i class="fal fa-calendar" />
      </template>

      <template #arrow-left>
        <i v-if="!hideArrows" class="fal fa-chevron-left fa-sm" />
      </template>

      <template #arrow-right>
        <i v-if="!hideArrows" class="fal fa-chevron-right fa-sm" />
      </template>

      <template #month="{ text }">
        <div class="text-sm">
          {{ text }}
        </div>
      </template>

      <template #year="{ year }">
        <div class="text-sm">
          {{ year }}
        </div>
      </template>

      <template #dp-input="{ value }">
        <div class="input-group">
          <div class="input-group-text">
            <i class="fal fa-calendar" />
          </div>

          <input
            :id="id"
            type="text"
            autocomplete="off"
            :readonly="readonly"
            :disabled="disabled"
            :value="value"
            class="form-control w-full"
          />
        </div>
      </template>
    </Datepicker>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import { inject } from "vue";
// Components
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
// Mixins
import InputMixn from "@/mixins/InputMixin";
// Constants
import dateFormats from "@/constants/dateFormats";

export default {
  components: {
    Datepicker
  },
  mixins: [InputMixn],
  props: {
    markers: {
      type: Array,
      default: () => []
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    startDate: {
      type: String,
      default: null
    },
    disableMonthYearSelect: {
      type: Boolean,
      default: false
    },
    disabledWeekDays: {
      type: Array,
      default: () => []
    },
    disableTimePicker: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    is24: {
      type: Boolean,
      default: false
    },
    hideArrows: {
      type: Boolean,
      default: false
    },
    minTime: {
      type: Object,
      default: null
    },
    maxTime: {
      type: Object,
      default: null
    },
    startTime: {
      type: Object,
      default: null
    }
  },
  setup(props, context) {
    // Misc
    const moment = inject("moment");

    // Methods
    const format = () => {
      const format = `${dateFormats.HUMAN_DATE_FORMAT} ${dateFormats.HUMAN_TIME_FORMAT}`;
      return props.modelValue ? moment(props.modelValue).format(format) : "";
    };

    const onUpdate = value => {
      context.emit(
        "update:modelValue",
        moment(value).format("YYYY-MM-DD HH:mm")
      );
    };

    return {
      format,
      onUpdate
    };
  }
};
</script>
