<template>
  <RequestCard :request="request">
    <template #content>
      <VLine :label="$t('app.observations')">
        <template #value>
          <div class="cursor-pointer" @click="onClickRedirectToObservation">
            {{ getText(request.evaluation?.texts, "name") }}
          </div>
        </template>
      </VLine>
    </template>

    <template #form>
      <VDateTime
        v-model="v.observation_scheduled_on.$model"
        :label="$t('app.scheduled_on')"
        :errors="v.observation_scheduled_on.$errors"
      />
    </template>

    <template #actions>
      <div
        class="flex items-center"
        :class="hasStart || hasResume ? 'justify-between' : 'justify-end'"
      >
        <VButton
          v-if="canSubmit"
          :is-loading="isSubmitting"
          :class="buttonClass"
          :label="buttonLabel"
          @click="onSubmit"
        />

        <VButton
          v-if="hasStart"
          class="btn-primary"
          :label="$t('app.start')"
          @click="onClickStart"
        />

        <VButton
          v-if="hasResume"
          class="btn-primary"
          :label="$t('app.resume')"
          @click="onClickResume"
        />
      </div>
    </template>
  </RequestCard>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { required } from "@/i18n/i18n-validators";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
import useForm from "@/composables/useForm";
import useOptions from "@/composables/useOptions";
// Components
import RequestCard from "@/views/teaching/requests/RequestCard";
import VDateTime from "@/components/inputs/VDateTime";
import VLine from "@/components/VLine";
import VButton from "@/components/VButton";

export default {
  components: {
    RequestCard,
    VDateTime,
    VLine,
    VButton
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, context) {
    // Misc
    const router = useRouter();
    const { t } = useI18n();

    // Data
    const value = ref({
      observation_scheduled_on: props.request?.observation_scheduled_on ?? ""
    });
    const rules = ref({ observation_scheduled_on: { required } });

    // Composables
    const { isRequesting: isSubmitting, request, isSuccess } = useRequest();
    const { getText } = useTexts();
    const { ALL_OPTIONS } = useOptions();
    const { v, onSave } = useForm(props, context, {
      value,
      rules
    });

    // Computed
    const buttonLabel = computed(() => {
      return props.request?.observation_scheduled_on
        ? t("app.reschedule")
        : t("app.schedule");
    });
    const buttonClass = computed(() => {
      return props.request?.observation_scheduled_on
        ? "btn-outline-primary"
        : "btn-primary";
    });
    const canSubmit = computed(() => {
      const hasCanSubmit = "can_submit" in props.request;
      return !hasCanSubmit || (hasCanSubmit && props.request.can_submit);
    });
    const status = computed(() => {
      return props.request?.status;
    });
    const hasResume = computed(() => {
      const isInProgress = status.value === ALL_OPTIONS.IN_PROGRESS.value;
      const isPaused = status.value === ALL_OPTIONS.PAUSED.value;

      return isInProgress || isPaused;
    });
    const hasStart = computed(() => {
      // eslint-disable-next-line
      const isScheduled = status.value === ALL_OPTIONS.OBSERVATION_SCHEDULED.value;
      const isPending = status.value === ALL_OPTIONS.PENDING.value;

      return isScheduled || isPending;
    });

    // Methods
    const onClickRedirectToObservation = () => {
      router.push({
        name: "teaching-evaluations-observations-update",
        params: {
          id: props.request.evaluation.id
        }
      });
    };

    const onSubmit = async () => {
      const data = await onSave();
      const response = await scheduleObservation(data);

      if (!isSuccess(response)) {
        return;
      }

      context.emit("refresh");
    };

    const scheduleObservation = async data => {
      if (!data) {
        return;
      }

      return await request({
        endpoint: "teaching.evaluations.schedule",
        pathParams: {
          id: props.request.id
        },
        data: {
          observation_scheduled_on: data.observation_scheduled_on
        }
      });
    };

    const onClickStart = () => {
      router.push({
        name: "begin-observation",
        params: {
          id: props.request?.id
        }
      });
    };

    const onClickResume = () => {
      router.push({
        name: "resume-observation",
        params: {
          id: props.request?.id
        }
      });
    };

    // Watch
    watch(
      () => props.request?.observation_scheduled_on,
      () => {
        // eslint-disable-next-line
        value.value.observation_scheduled_on = props.request?.observation_scheduled_on;
      }
    );

    return {
      hasStart,
      hasResume,
      canSubmit,
      onClickStart,
      onClickResume,
      buttonLabel,
      buttonClass,
      v,
      onClickRedirectToObservation,
      onSubmit,
      // useTexts
      getText,
      // useRequest
      isSubmitting
    };
  }
};
</script>
