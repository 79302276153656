<template>
  <RegistrationCard :request="request">
    <template #content>
      <slot name="content" />
    </template>

    <template #form>
      <slot name="form" />
    </template>

    <template #actions>
      <slot name="actions">
        <div class="flex justify-end">
          <VButton
            v-if="canSubmit"
            :is-loading="isSubmitting"
            class="btn-primary"
            :label="$t('app.submit')"
            @click="$emit('submit')"
          />
        </div>
      </slot>
    </template>
  </RegistrationCard>
</template>

<script>
import { computed } from "vue";
// Components
import RegistrationCard from "@/views/teaching/RegistrationCard";
import VButton from "@/components/VButton";

export default {
  components: {
    RegistrationCard,
    VButton
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },
  emits: ["submit"],
  setup(props) {
    const canSubmit = computed(() => {
      const hasCanSubmit = "can_submit" in props.request;
      return !hasCanSubmit || (hasCanSubmit && props.request.can_submit);
    });

    return {
      canSubmit
    };
  }
};
</script>
