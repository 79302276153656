<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.observations", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.teaching_requests_observations") }}
        </VHint>
      </VTitle>

      <VSwitch v-model="scheduled" hide-value>
        <template #label="{ id }">
          <label class="space" :for="id">
            {{ $t("app.scheduled") }}
          </label>
        </template>
      </VSwitch>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert
      v-else-if="data.length === 0"
      :text="$t('app.no_submission_requests')"
    />

    <div v-else class="grid gap-8">
      <Observation
        v-for="observation in data"
        :key="observation.id"
        :request="observation"
        @refresh="getData"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import Observation from "./Observation";
import VHint from "@/components/VHint";
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";
import VSwitch from "@/components/inputs/VSwitch";

export default {
  components: {
    Observation,
    VHint,
    VAlert,
    VTitle,
    VSwitch
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { data, isLoading, read } = useReadMultiple();

    // Data
    const scheduled = ref(false);

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${t("app.observations", 2)} - ${t("app.requests", 2)} - ${t("app.teaching")}`;

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.evaluations.requests",
        queryParams: {
          scheduled: scheduled.value
        },
        showToaster: false
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Watch
    watch(scheduled, getData);

    return {
      documentTitle,
      scheduled,
      // useReadMultiple
      data,
      isLoading,
      getData
    };
  }
};
</script>
